import React from "react";
import { experience } from "../portfolio";
import { Container, Row } from "reactstrap";

import { Fade } from "react-reveal";

import ExperienceCard from "../components/ExperienceCard";

const Experience = () => {
	return (
		<section className='section section-lg'>
			<Container>
				<Fade left duration={1000} distance='40px'>
					<div className='d-flex p-4'>
						<div>
							<div className='icon icon icon-shape bg-gradient-white shadow rounded-circle text-info'>
								💼
							</div>
						</div>
						<div className='pl-4'>
							<h4 className='display-3 text mb-5'>Experience</h4>
						</div>
					</div>
					<Row className='row-grid justify-content-center mt-5 align-items-center'>
						{experience.map(data => {
							return <ExperienceCard data={data} />;
						})}
					</Row>
				</Fade>
			</Container>
		</section>
	);
};

export default Experience;
