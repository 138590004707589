import React from "react";

import { Card, CardBody, Col, Button } from "reactstrap";

import { Fade } from "react-reveal";

const LinksCard = ({ data }) => {
	return (
		<Col lg='4'>
			<Fade bottom duration={1000} distance='40px'>
				<Card className='shadow-lg--hover shadow mt-4'>
					<CardBody>
						<div className='d-flex px-3'>
							<div>
								<h5 className='text-black'>{data.name}</h5>
								{data.link ? (
									<Button
										className='btn-neutral btn-icon'
										color='secondary'
										href={data.link.url}
										target='_blank'
									>
										<span className='btn-inner--icon'>
											<i className='fa fa-code mr-2' />
										</span>
										<span
											className='nav-link-inner--text ml-1'
											style={{ textTransform: "none" }}
										>
											{data.link.name}
										</span>
									</Button>
								) : null}
							</div>
						</div>
					</CardBody>
				</Card>
			</Fade>
		</Col>
	);
};

export default LinksCard;
