import emoji from "react-easy-emoji";

import googlelogo from "./assets/img/icons/common/datoin.png";

export const greetings = {
	name: "Swagat Kumar",
	title: "hey there, I'm Swagat!",
	description:
		"an aspiring full-stack web developer from NIT Rourkela. i love to develop stable and efficient webapps with Express, NodeJS, ReactJS, MongoDB, Postgres etc. besides all this, i have hands-on experience with AWS services like S3, Lambda, API Gateway, Route 53, DynamoDB, etc. and also love to explore new technologies such as GraphQL, MeteorJS, and others.",
	resumeLink:
		"https://drive.google.com/file/d/1Rpc7QuAGmvi8tZc7HT3nUjuVK8oXxEMt/view?usp=sharing",
};

export const openSource = {
	githubUserName: "Swagat-Kumar",
};

export const contact = {};

export const socialLinks = {
	facebook: "https://www.facebook.com/swagatkumarrocky",
	instagram: "https://www.instagram.com/swagatkumarflute/",
	twitter: "https://twitter.com/SwagatK24497938",
	github: "https://github.com/Swagat-Kumar",
	linkedin: "https://www.linkedin.com/in/swagat-kumar/",
};

export const skillsSection = {
	title: "my skills 💻",
	subTitle:
		"learning new technologies is one of my favourite things to do! don't be startled if this list appears to be growing too swiftly!",
	skills: [
		emoji("🧬 developing highly structured projects with MERN/PERN stack"),
		emoji("⚛️ writing clean and readable code with functional programming"),
		emoji("🖥️ being a team player and delivering up to the requirements"),
		emoji("🤓 learning new technologies and concepts quickly!"),
	],

	softwareSkills: [
		{
			skillName: "nodejs",
			fontAwesomeClassname: "logos:nodejs-icon",
		},
		{
			skillName: "express",
			fontAwesomeClassname: "logos:express",
		},
		{
			skillName: "reactjs",
			fontAwesomeClassname: "vscode-icons:file-type-reactjs",
		},
		{
			skillName: "mongoDB",
			fontAwesomeClassname: "vscode-icons:file-type-mongo",
		},
		{
			skillName: "postgres",
			fontAwesomeClassname: "vscode-icons:file-type-sql",
		},
		{
			skillName: "redux",
			fontAwesomeClassname: "logos:redux",
		},
		{
			skillName: "rest",
			fontAwesomeClassname: "vscode-icons:file-type-rest",
		},
		{
			skillName: "aws",
			fontAwesomeClassname: "logos:aws",
		},
		{
			skillName: "javascript",
			fontAwesomeClassname: "logos:javascript",
		},
		{
			skillName: "css3",
			fontAwesomeClassname: "vscode-icons:file-type-css",
		},
		{
			skillName: "html-5",
			fontAwesomeClassname: "vscode-icons:file-type-html",
		},
		{
			skillName: "bootstrap-5",
			fontAwesomeClassname: "logos-bootstrap",
		},
		{
			skillName: "yarn",
			fontAwesomeClassname: "vscode-icons:file-type-yarn",
		},
		{
			skillName: "sql-database",
			fontAwesomeClassname: "logos:mysql",
		},

		{
			skillName: "python",
			fontAwesomeClassname: "logos:python",
		},
		{
			skillName: "java",
			fontAwesomeClassname: "logos:java",
		},
		{
			skillName: "git",
			fontAwesomeClassname: "logos:github-icon",
		},
	],
};

export const SkillBars = [
	{
		Stack: "MERN stack", //Insert stack or technology you have experience in
		progressPercentage: "95", //Insert relative proficiency in percentage
	},
	{
		Stack: "PERN stack", //Insert stack or technology you have experience in
		progressPercentage: "90", //Insert relative proficiency in percentage
	},
	{
		Stack: "AWS", //Insert stack or technology you have experience in
		progressPercentage: "80", //Insert relative proficiency in percentage
	},
	{
		Stack: "Backend",
		progressPercentage: "85",
	},
	{
		Stack: "Frontend",
		progressPercentage: "90",
	},
	{
		Stack: "System Design",
		progressPercentage: "80",
	},
	{
		Stack: "Data Structures and Algorithms",
		progressPercentage: "75",
	},
];

export const educationInfo = [
	{
		schoolName: "NIT, Rourkela",
		subHeader: "B.Tech. in Electrical Engineering",
		duration: "July 2018 - April 2022",
		desc: "CGPA : 8.33/10",
	},
	{
		schoolName: "M.B.S Public School, Bhubaneswar",
		subHeader: "Intermediate",
		duration: "May 2016 - May 2018",
		desc: "Percentage : 88%",
	},
	{
		schoolName: "Blessed Sacrament High School, Puri",
		subHeader: "Matriculation",
		duration: "May 2015 - May 2016",
		desc: "Percentage : 96.5%",
	},
];

export const experience = [
	{
		role: "Full Stack Intern",
		company: "Datoin",
		companylogo: googlelogo,
		date: "May 2021 – July 2021",
		desc: "Video Conferencing Web App",
		descBullets: [
			"i worked in a small team, predominately as a back-end engineer.",
			"to build a robust back-end, i used Express, NodeJS, and Postgres.",
			"planned out Entity Relationships for the whole system.",
		],
	},
];

export const projects = [
	{
		name: "Minesweeper Game",
		desc: "using ReactRedux and AWS S3, CloudFront\nand Route53, i created a minesweeper\nweb with a simple design and deployed it.",
		link: {
			name: "minesweeper.swagatkumar",
			url: "https://minesweeper.swagatkumar.net/",
		},
	},
	{
		name: "Band Website",
		desc: "to deploy a complete serverless website \nfor a musical band, i utilised ReactRedux\nfor the frontend and AWS Lambda with\nDynamo database for the backend.",
		link: {
			name: "heartbeats.tk",
			url: "https://heartbeats.tk/",
		},
	},
	{
		name: "Simple Todo App",
		desc: "here i have used GraphQL (queries, mutations, subscriptions) and React with AWS Amplify to develop a simple Todo App with Amazon Cognito Auth and DynamoDB as database.",
		link: {
			name: "todoapp.swagatkumar",
			url: "https://todoapp.swagatkumar.net/",
		},
	},
];
export const links = [
	{
		name: "LeetCode",
		link: {
			name: "swagatkumar",
			url: "https://leetcode.com/swagatkumar/",
		},
	},
	{
		name: "HackerRank",
		link: {
			name: "SwagatKumar",
			url: "https://www.hackerrank.com/SwagatKumar?hr_r=1",
		},
	},
	{
		name: "CodeChef",
		link: {
			name: "swagatkumarnit",
			url: "https://www.codechef.com/users/swagatkumarnit",
		},
	},
	{
		name: "HackerEarth",
		link: {
			name: "@Swagat-Kumar",
			url: "https://www.hackerearth.com/@Swagat-Kumar",
		},
	},
	{
		name: "Github",
		link: {
			name: "Swagat-Kumar",
			url: "https://github.com/Swagat-Kumar",
		},
	},
	{
		name: "LinkedIn",
		link: {
			name: "swagat-kumar",
			url: "https://www.linkedin.com/in/swagat-kumar/",
		},
	},
];
