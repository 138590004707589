import React from "react";

import { links } from "../portfolio";
import { Container, Row } from "reactstrap";
import LinksCard from "../components/LinksCard";
import { Fade } from "react-reveal";

const Projects = () => {
	return (
		<section className='section section-lg '>
			<Container>
				<Fade bottom duration={1000} distance='40px'>
					<div className='d-flex p-4'>
						<div>
							<div className='icon icon icon-shape bg-gradient-white shadow rounded-circle text-info'>
								🔖
							</div>
						</div>
						<div className='pl-4'>
							<h4 className='display-3 text'>Relevant Links</h4>
						</div>
					</div>
					<Row className='row-grid align-items-center'>
						{links.map(data => {
							return <LinksCard data={data} />;
						})}
					</Row>
				</Fade>
			</Container>
		</section>
	);
};

export default Projects;
