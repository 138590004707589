import React from "react";

import { projects } from "../portfolio";
import { Container, Row } from "reactstrap";
import ProjectsCard from "../components/ProjectsCard";
import { Fade } from "react-reveal";

const Projects = () => {
	return (
		<section className='section bg-gradient-dark py-5'>
			<Container>
				<Fade bottom duration={1000} distance='40px'>
					<div className='d-flex p-4'>
						<div>
							<div className='icon icon icon-shape bg-gradient-white shadow rounded-circle text-info'>
								🐱‍💻
							</div>
						</div>
						<div className='pl-4'>
							<h4 className='display-3 text-white'>Projects</h4>
						</div>
					</div>
					<Row className='row-grid align-items-center'>
						{projects.map(data => {
							return <ProjectsCard data={data} />;
						})}
					</Row>
				</Fade>
			</Container>
			<div className='separator separator-bottom separator-skew zindex-100'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					preserveAspectRatio='none'
					version='1.1'
					viewBox='0 0 2560 100'
					x='0'
					y='0'
				>
					<polygon
						className='fill-white'
						points='2560 0 2560 100 0 100'
					/>
				</svg>
			</div>
		</section>
	);
};

export default Projects;
